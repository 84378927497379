import React from 'react';

import { PrismicRichText } from 'types/prismicRichtext';
import { RichText } from 'components/contentful/rich-text/RichText';
import { ContainerDiv } from 'components/container/ContainerDiv';

import s from './Text.scss';

interface IProps {
  richText: Array<PrismicRichText>;
  parent?: 'articleSlices' | 'pageSlices' | null;
  pageThemeLight?: boolean;
}

export const Text = ({ richText, anchorId, parent, pageThemeLight=false }: any) => {
  return (
    <div id={anchorId} className={s('sliceText', parent, {pageThemeLight} )} >
      <ContainerDiv>
        <div className={s.sliceText__row}>
          <div className={s.sliceText__row__col}>
            <RichText pageThemeLight={pageThemeLight} fontSizeBig={true} richText={richText} />
          </div>
        </div>
      </ContainerDiv>
    </div>
  );
};
