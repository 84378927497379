import React, { useRef, useEffect, useContext } from 'react';
import gsap from 'gsap';

import { ContainerDiv } from 'components/container/ContainerDiv';
import { PrismicImage } from 'types/prismicImage';
import { Image as Img } from 'components/contentful/image/Image';

import { scroll } from 'constants/Scroll'
import { RichText } from 'components/rich-text/RichText';

import { UIContext } from 'context/ui';

import s from './Image.scss';

interface IProps {
  image: PrismicImage;
  caption?: Array<{ text: string }> | null;
  alignment?: string | null;
  parent?: 'articleSlices' | 'pageSlices' | null;
  pageThemeLight?: boolean;
}

export const Image = ({ image, caption, alignment, parent, pageThemeLight=false }: any) => {
  const { isMobile, isDesktop } = useContext(UIContext);

  const imgWrapRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLDivElement>(null);
  let tl:GSAPTimeline;

  useEffect(() => {
    if (imgWrapRef.current && imgRef.current) {
    
      setTimeout(() => {

        if (isDesktop) {
          tl = gsap.timeline({
            scrollTrigger: {
              scroller: scroll.container,
              trigger: imgWrapRef.current,
              start: `top-=${window.innerHeight * 1.3}`
            }
          });
        }

        if (isMobile) {
          tl = gsap.timeline({
            scrollTrigger: {
              trigger: imgWrapRef.current,
              start: `top-=${window.innerHeight * 1.3}`
            }
          });
        }

        const scale = isDesktop ? 1.3 : 1;

        if (isDesktop && imgRef.current) {
          if (tl && (alignment === 'Left Side' || alignment === 'Right Side')) {
            const xPercent = alignment === 'Right Side' ? 50 : -50;

            tl
              .addLabel('start')
              .set([imgWrapRef.current, imgRef.current], { autoAlpha: 1 })
              .set(imgWrapRef.current, { xPercent })
              .set(imgRef.current, { xPercent: -xPercent, scale })
              .to(imgWrapRef.current, { duration: 1.8, ease: 'Power3.easeOut', xPercent: 0 }, 'start')
              .to(imgRef.current, { duration: 1.8, ease: 'Power3.easeOut', xPercent: 0, scale: 1 }, 'start')
              .to(imgRef.current, { duration: 2.5, ease: 'Power3.easeOut', scale: 1 }, 'start');
          }

          if (tl && (alignment === 'Center' || alignment === 'Center Fullscreen')) {
            tl
              .addLabel('start')
              .set([imgWrapRef.current, imgRef.current], { autoAlpha: 1 })
              .set(imgRef.current, { scale })
              .to(imgRef.current, { duration: 2.5, ease: 'Power3.easeOut', scale: 1 }, 'start');
          }
        }

        if (isMobile && imgRef.current) {
          if (tl) {
            const xPercent = -70;

            tl
              .addLabel('start')
              .set([imgWrapRef.current, imgRef.current], { autoAlpha: 1 })
              .set(imgWrapRef.current, { xPercent })
              .set(imgRef.current, { xPercent: -xPercent })
              .to(imgWrapRef.current, { duration: 1.8, ease: 'Power3.easeOut', xPercent: 0 }, 'start')
              .to(imgRef.current, { duration: 1.8, ease: 'Power3.easeOut', xPercent: 0, scale: 1 }, 'start')
              .to(imgRef.current, { duration: 2.5, ease: 'Power3.easeOut' }, 'start');
          }
        }
      
      }, 1500)
      
    }
  }, [isMobile, isDesktop])

  useEffect( ():any => {
    return () => tl && tl.kill()
  },[])

  return (
    <div className={s('sliceImage', 'align'+alignment?.replace(' ',''), parent, {pageThemeLight} )} >
      <ContainerDiv>
        <div className={s.sliceImage__row}>
          <div ref={imgWrapRef} className={s.sliceImage__row__col}>
            <div ref={imgRef} className={s.sliceImage__image}>
              <Img image={image} />
            </div>
          </div>
        </div>
        { caption && (
          <div className={s.sliceImage__caption__row}>
            <div className={s.sliceImage__caption__row__col}>
              <p>{caption}</p>
            </div>
          </div>
        )}

      </ContainerDiv>
    </div>
  );
};
