import React from 'react';
import { Text } from 'components/news/slices/Text';
import { Image } from 'components/news/slices/Image';
import { Video } from 'components/news/slices/Video';
import { BlockQuote } from 'components/news/slices/BlockQuote';
import { CTA } from 'components/news/slices/CTA';
import { Embed } from 'components/news/slices/Embed';
import { ImagesCarousel } from 'components/news/slices/ImagesCarousel';
import { QuoteBigImage } from 'components/quote/QuoteBigImage';
import { MediaTextContentful } from 'components/contentful/media-text/MediaText';
import { InfoListContentful } from 'components/contentful/info-list/InfoList';

const BLOCKQUOTE = 'blockQuote';
const IMAGE = 'imageItem';
const QUOTE = 'quote';
const RICH_TEXT = 'richText';
const VIDEO = 'videosGalleryItem';
const INFOLIST = 'infoList';
const MEDIATEXT = 'mediaText';
const CTA_SLICE = 'cta';
const EMBED_HTML = 'embedHtml';
const IMAGES_CAROUSEL = 'imagesCarousel';


export const Slices = ({ data = [] }: any) => {
  if (!data) return null;

  return (
    <>
      {data.map((slice: any, index: number) => {
        const key = `slice-${slice.type}-${index}`;
        
        switch (slice.sys.contentType.sys.id) {
          case RICH_TEXT:
            return (
              <Text 
                key={key} 
                richText={slice.text} 
                parent="pageSlices"
                anchorId={slice.anchorId}
              />
            );

          case IMAGE:
            return (
              <Image
                key={key}
                image={slice.image}
                caption={slice.caption}
                alignment={slice.layout}
                parent="pageSlices"
              />
            );

          case VIDEO:
            return (
              <Video 
                key={key} 
                video={slice} 
                parent="pageSlices"
              />
              );
          
          case BLOCKQUOTE:
            return (
              <BlockQuote 
                key={key} 
                text={slice.text} 
                author={slice.author} 
                parent="pageSlices"
              />
            );

          case QUOTE:
            return (
              <QuoteBigImage 
                key={key} 
                text={slice.text} 
                author_name={slice.author} 
                image={slice.avatar} 
              />
            );

          case INFOLIST:
            return (
              <InfoListContentful
                infoList={slice}
                key={key}
                titlePlacement='inside'
              />
            );
          
          case MEDIATEXT:
            return (
              <MediaTextContentful
                key={key} 
                mediaText={slice}
              />
            );

          case CTA_SLICE:
            return (
              <CTA 
                key={key} 
                label={slice.label} 
                destination={slice.href}
                file={slice.file}
                //parent="pageSlices"
              />
            );
  
          case EMBED_HTML:
            return (
              <Embed 
                key={key} 
                code={slice.html.html} 
                parent="pageSlices"
              />
            );

          case IMAGES_CAROUSEL:
            return (
              <ImagesCarousel
                key={key}
                items={slice.items}
                parent="pageSlices"
              />
            );

          default:
            return null;
        }
        
      })}
    </>
  );
};
