import React from 'react';

import { ContainerDiv } from 'components/container/ContainerDiv';
import { VideoPlayer } from 'components/contentful/video-player/VideoPlayer';
import { RichText } from 'components/rich-text/RichText';

import s from './Video.scss';

interface IProps {
  video?: any;
  caption?: string;
  parent?: 'articleSlices' | 'pageSlices' | null;
  pageThemeLight?: boolean;
}

export const Video = ({ video, parent, pageThemeLight=false}: any) => {
  if (!video) return null;

  return (
    <div className={s('sliceVideo', 'type'+video.provider_name, parent, {pageThemeLight})} >
      <ContainerDiv>
        <div className={s.sliceVideo__row}>
          <div className={s.sliceVideo__row__col}>
            <div className={s.sliceVideo__video} 
              /*style={{paddingTop:(video.height/video.width*100)+'%'}}*/  >
              <VideoPlayer videoItem={video} mute={false}/>
            </div>
          </div>
        </div>
      </ContainerDiv>
    </div>
  )
};
