import React from 'react';

import { ContainerDiv } from 'components/container/ContainerDiv';

import s from './Embed.scss';

interface IProps {
  code?: any;
  parent?: 'articleSlices' | 'pageSlices' | null;
  pageThemeLight?: boolean;
}

const htmlDecode = (code: string) => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    var e = document.createElement('div');
    e.innerHTML = code;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  } else {
    return '';
  }
};

export const Embed = ({ code , parent, pageThemeLight=false}: IProps) => {
  if (!code) return null;

  return (
    <div className={s('sliceEmbed', parent, {pageThemeLight})} >
      <ContainerDiv>
        <div className={s.sliceEmbed__row}>
          <div className={s.sliceEmbed__row__col}>
            <div className={s.sliceEmbed__row__col__inner} dangerouslySetInnerHTML={{ __html: code }} />
          </div>
        </div>
      </ContainerDiv>
    </div>
  )
};