import React, { useRef, useEffect, useContext } from 'react';
import gsap from 'gsap';
import SplitText from '../../../utils/SplitText/SplitText';

import { ContainerDiv } from 'components/container/ContainerDiv';

import { UIContext } from 'context/ui';
import { PrismicRichText } from 'types/prismicRichText';
import { RichText } from 'components/contentful/rich-text/RichText';

import { scroll } from 'constants/Scroll'

import s from './BlockQuote.scss';

gsap.registerPlugin(SplitText);

interface IProps {
  text?: PrismicRichText | null;
  author?: Array<{ text: string }> | null;
  parent?: 'articleSlices' | 'pageSlices' | null;
  pageThemeLight?: boolean;
}

export const BlockQuote = ({ text, author, parent, pageThemeLight=false }: IProps) => {
  const { isMobile, isDesktop } = useContext(UIContext);

  const quoteRef = useRef<HTMLElement>(null);
  const authorRef = useRef<HTMLElement>(null);
  let tl:GSAPTimeline;

  useEffect(() => {
    
    if (quoteRef.current) {
      // set timeout to split lines properly
      setTimeout(() => {
        if(isDesktop && quoteRef.current) {
          tl = gsap.timeline({
            scrollTrigger: {
              scroller: scroll.container,
              trigger: quoteRef.current,
            }
          });
        }

        if(isMobile && quoteRef.current) {
          tl = gsap.timeline({
            scrollTrigger: quoteRef.current,
          });
        }

        /*let quoteLines = new SplitText(quoteRef.current, { type: 'lines'}).lines;
        quoteLines = new SplitText(quoteLines, { type: 'lines' }).lines;

        if (tl) {
          tl
            .addLabel('start')
            .set(quoteRef.current, { autoAlpha: 1 })
            .set(authorRef.current, { autoAlpha: 0 })
            .set(quoteLines, { yPercent: 100 })
            .to(quoteLines, { duration: 1.5, stagger: 0.1, ease: 'Power3.easeInOut', yPercent: 0 })
            .to(authorRef.current, { duration: 0.5, autoAlpha: 1 }, 'start+=1.3');
        }*/

        if (tl) {
          tl
            .addLabel('start')
            .set(quoteRef.current, { autoAlpha: 0 })
            .to(quoteRef.current, { duration: 0.65, autoAlpha: 1 }, 'start+=1.3');
        }

      }, 1500);
    }
  }, [isMobile, isDesktop])

  useEffect( ():any => {
    return () => tl && tl.kill()
  },[])

  return (
    <div className={s('sliceBlockQuote', parent, {pageThemeLight}, (text && !author) ? 'center' : '')} >
      <ContainerDiv>
        <div className={s.sliceBlockQuote__row}>
          <div className={s.sliceBlockQuote__row__col}>
            { text && (
              <blockquote ref={quoteRef} className={s.sliceBlockQuote__quote}>
                <RichText richText={text}/>
              </blockquote>
            )}
            { author && (
              <div ref={authorRef} className={s.sliceBlockQuote__caption}>
                - {author}
              </div>
            )}
          </div>
        </div>
      </ContainerDiv>
    </div>
  )
};
