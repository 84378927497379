import React from 'react';

import { ContainerDiv } from 'components/container/ContainerDiv';
import { Button } from 'components/button/Button';
import s from './CTA.scss';

interface IProps {
  label?: Array<{ text: string }> | null;
  destination?: any;
  parent?: 'articleSlices' | 'pageSlices' | null;
  pageThemeLight?: boolean;
}

export const CTA = ({ label, destination, file, parent, pageThemeLight=false}: any) => {
  if (!label) return null;
  const href = destination || (file && file.file.url);
  return (
    <div className={s('sliceCTA', parent, {pageThemeLight})} >
      <ContainerDiv>
        <div className={s.sliceCTA__row}>
          <div className={s.sliceCTA__row__col}>
            <Button label={label} href={href} />
          </div>
        </div>
      </ContainerDiv>
    </div>
  )
};