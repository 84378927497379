import React, { useEffect, useState } from 'react';
import {RichText} from 'prismic-reactjs';
import 'flickity/dist/flickity.min.css';

import { ContainerDiv } from 'components/container/ContainerDiv';

import s from './ImagesCarousel.scss';


export const ImagesCarousel = ({ 
  items , 
  parent, 
  pageThemeLight
}: any) => {

  const [carousel, setCarousel] = useState(null);
  const carRef = null;
  const initCarousel = (element: HTMLElement) => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const Flickity = require('flickity');
        require('flickity-imagesloaded');
        const carouselInstance = new Flickity( element, {
          // options
          wrapAround: true,
          adaptiveHeight: true,
          arrowShape: 'M23.7,48.1L60,90l4.3-3.8L31.4,48.3L64.2,14l-4.1-4L23.7,48.1z',
          imagesLoaded: true
        });
    }
  }

  const featuredContent = items?.map((item , index) => (
    <li key={index} className={s('sliceImagesCarousel__container__item', 'carousel-cell', (items?.length > 7) && 'many-items')}>
      <figure>
        <img src={item.image.file.url} alt={item.image.description}/>
      </figure>
      <div className={s('sliceImagesCarousel__container__caption', 'caption')}>
        {item.caption}
      </div>
    </li>
  ));
  
  return (
    <div className={s('sliceImagesCarousel', parent, {pageThemeLight})} >
      <ContainerDiv>
        <div className={s.sliceImagesCarousel__row}>
          <div className={s.sliceImagesCarousel__row__col}>
            <ul ref={initCarousel} className={s('sliceImagesCarousel__container', 'main-carousel', pageThemeLight && 'themeLight ')}>
              {featuredContent}
            </ul>
          </div>
        </div>
      </ContainerDiv>
    </div>
  )
};