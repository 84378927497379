import React, { useEffect, useRef, useContext } from 'react';
import gsap from 'gsap';
import DrawSVGPlugin from 'utils/DrawSVGPlugin.js';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { PrismicImage } from 'types/prismicImage';
import { PrismicRichText } from 'types/prismicRichText';
import { RichText } from 'components/contentful/rich-text/RichText';

import { Container } from 'components/container/Container';
import { Image } from 'components/contentful/image/Image';

import { scroll } from 'constants/Scroll'

import s from './QuoteBigImage.scss';

import { UIContext } from 'context/ui';

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(ScrollTrigger);

interface IProps {
  text: PrismicRichText | null;
  image?: PrismicImage;
  author_name?: Array<{ text: string }> | null;
  author_position?: Array<{ text: string }> | null;
  pageThemeLight?: boolean;
}

export const QuoteBigImage = ({image,text,author_name, author_position, pageThemeLight=false }: any) => {

  const { isMobile, isDesktop } = useContext(UIContext);

  const lineRef = useRef(null);
  const lineRef_right = useRef(null);
  const lineRef_bottom = useRef(null);
  const lineRef_left = useRef(null);
  const lineRef_top_mob = useRef(null);
  const lineRef_top_desk = useRef(null);
  const imgWrapRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isDesktop) {
      if (lineRef_top_desk.current) {
        setTimeout(() => {
          gsap.from(lineRef_top_desk.current, {
            drawSVG: '0%',
            duration: .5,
            ease: 'linear',
            delay: 0,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef_top_desk.current,
              start: "top 88%",
              end: "bottom",
              once: true,
            },
          });
        }, 1500);
      };
      if (lineRef_left.current) {
        setTimeout(() => {
          gsap.from(lineRef_left.current, {
            drawSVG: '0%',
            duration: .5,
            ease: 'linear',
            delay: .5,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef_top_desk.current,
              start: "top 88%",
              end: "bottom",
              once: true,
            },
          });
        }, 1500);
      };
      if (lineRef_bottom.current) {
        setTimeout(() => {
          gsap.from(lineRef_bottom.current, {
            drawSVG: '0%',
            duration: .5,
            ease: 'linear',
            delay: 1,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef_top_desk.current,
              start: "top 88%",
              end: "bottom",
              once: true,
            },
          });
        }, 1500);
      };
      if (lineRef_right.current) {
        setTimeout(() => {
          gsap.from(lineRef_right.current, {
            drawSVG: '0%',
            duration: .5,
            ease: 'linear',
            delay: 1.5,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef_top_desk.current,
              start: "top 88%",
              end: "bottom",
              once: true,
            },
          });
        }, 1500);
      };
    }
  }, [isDesktop]);

  useEffect(() => {
    if (isMobile) {
      if (lineRef_top_mob.current) {
        setTimeout(() => {
          gsap.from(lineRef_top_mob.current, {
            drawSVG: '0%',
            duration: .5,
            ease: 'linear',
            delay: 0,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef_top_mob.current,
              start: "top 88%",
              end: "bottom",
              once: true,
            },
          });
        }, 1500);
      };
      if (lineRef_left.current) {
        setTimeout(() => {
          gsap.from(lineRef_left.current, {
            drawSVG: '0%',
            duration: .5,
            ease: 'linear',
            delay: .5,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef_top_mob.current,
              start: "top 88%",
              end: "bottom",
              once: true,
            },
          });
        }, 1500);
      };
      if (lineRef_bottom.current) {
        setTimeout(() => {
          gsap.from(lineRef_bottom.current, {
            drawSVG: '0%',
            duration: .5,
            ease: 'linear',
            delay: 1,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef_top_mob.current,
              start: "top 88%",
              end: "bottom",
              once: true,
            },
          });
        }, 1500);
      };
      if (lineRef_right.current) {
        setTimeout(() => {
          gsap.from(lineRef_right.current, {
            drawSVG: '0%',
            duration: .5,
            ease: 'linear',
            delay: 1.5,
            scrollTrigger: {
              scroller: scroll.container,
              trigger: lineRef_top_mob.current,
              start: "top 88%",
              end: "bottom",
              once: true,
            },
          });
        }, 1500);
      };
    }
  }, [isMobile]);

  useEffect(() => {
    if (isDesktop && imgWrapRef.current && imgRef.current) {
      gsap.set(imgRef.current.querySelector('figure'), { scale: 1.3 });
      gsap.fromTo(
        imgRef.current,
        {
          y: -100
        },
        {
          y: 100,
          scrollTrigger: {
            scroller: scroll.container,
            trigger: imgWrapRef.current,
            scrub: true,
          }
        }
      );
    }
  }, [isMobile, isDesktop])

  return (
    <div className={s('quote', {pageThemeLight})}>
      <Container>
        <div className={s.quote__row}>

          <div className={s.quote__col}>
            <div className={s.quote__container}>
              <h2 className={`u-visually-hidden`}>Image Quote</h2>
              <div className={s.quote__border}>
              { isMobile && 
                <svg className={s.b_top} xmlns="http://www.w3.org/2000/svg" width="1000" height="768" fill="none">
                  <path
                    ref={lineRef_top_mob}
                    d="M999,1H41C18.9,1,1,18.9,1,41v59" />
                </svg>
              }
              { isDesktop && 
                <svg className={s.b_top} xmlns="http://www.w3.org/2000/svg" width="1000" height="768" fill="none">
                  <path
                    ref={lineRef_top_desk}
                    d="M999,1H100C45.3,1,1,45.3,1,100" />
                </svg>
              }
                <svg className={s.b_right} xmlns="http://www.w3.org/2000/svg" width="820" height="1768" fill="none">
                  <line
                    ref={lineRef_right}
                    x1="819" y1="100%" x2="819" y2="0"/>
                </svg>
                <svg className={s.b_bottom} xmlns="http://www.w3.org/2000/svg" width="1000" height="768" fill="none">
                  <line
                    ref={lineRef_bottom}
                    x1="1" y1="767" x2="100%" y2="767" />
                </svg>
                <svg className={s.b_left} xmlns="http://www.w3.org/2000/svg" width="820" height="820" fill="none">
                  <line 
                    ref={lineRef_left}
                    x1="1" y1="100" x2="1" y2="100%"/>
                </svg>
              </div>
              {text && (
                <blockquote className={s.quote__text}>
                  &ldquo;<RichText richText={text}/>&rdquo;
                </blockquote>
              )}
              <div>
                {author_name && (<div className={s.quote__authorName}>{author_name}</div>)}
                {author_position && (<div className={s.quote__authorPosition}>{author_position[0].text}</div>)}
              </div>
            </div>
          </div>

          <div className={s('quote__col', 'right')}>
            {image && (
              <div ref={imgWrapRef} className={s.quote__image }>
                <div ref={imgRef}>
                  <Image image={image} srcSetMobile={true} />
                </div>
              </div>
            )}
          </div>

        </div>
      </Container>
    </div>
)};
