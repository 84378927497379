
import React from 'react';
import { graphql } from 'gatsby';

import {
  Prismic_Template_Page,
  Prismic_Shared_ContentsuiConnectionConnection
} from 'types/prismic';

import { Seo } from 'components/contentful/seo/Seo';
import { Hero } from 'components/contentful/hero/Hero';
import { Slices } from 'components/page/slices/Slices';
import { Billboard } from 'components/contentful/billboard/Billboard';
import { BgGradient } from 'components/bg-svgs/gradients/BgGradient';
import { prismicLinkResolver } from 'utils/linkResolver';
import { WrapperBgs } from 'components/container/WrapperBgs';
import { Mesh } from 'components/bg-svgs/mesh/mesh/Mesh';

interface IProps {
  data?: {
    prismic?: {
      template_page?: Prismic_Template_Page;
      allShared_contentsuis: Prismic_Shared_ContentsuiConnectionConnection | null;
    };
  };
  pageContext?: any;
  location?: Location;
}

export const Page = ({ data, pageContext, location }: IProps) => {
   if (!pageContext.data) return null;

  const page = pageContext.data;

  return (
    <>

      <Seo
        seo={page.compose__page[0].seo}
        url={location!.pathname}
      />

      <BgGradient />

      <Hero
        hero={page.hero}
      />

      <div className='below-the-hero'>
        {page.body && <Slices data={page.body} />}
      </div>
      {
        page.billboard && 
          <WrapperBgs>
            <Mesh size="small" />
            <Billboard
              billboard={page.billboard}
            />
          </WrapperBgs>
      }
    </>
  );
};

export default Page;

/* export const query = graphql`
  query template_page($uid: String!) {
    prismic {
      template_page(uid: $uid, lang: "en-us") {
        _meta {
          uid
        }
        
        page_title
        page_img_desktop
        page_img_mobile

        meta_title
        meta_description
        social_cards__image_facebook__1200x630px_
        social_cards__image_twitter
        meta_noindex

        body {
          ... on PRISMIC_Template_pageBodyText {
            type
            label
            primary {
              page_slice_richtext
            }
          }
          ... on PRISMIC_Template_pageBodyQuote {
            type
            label
            primary {
              page_slice_quote
              page_slice_quote_author
              page_slice_quote_portrait
            }
          }
          ... on PRISMIC_Template_pageBodyImage {
            type
            label
            primary {
              page_slice_img
              page_slice_img_caption
              page_slice_img_layout
            }
          }
          ... on PRISMIC_Template_pageBody2Col_text_image {
            type
            label
            primary {
              page_slice_2col_mirrored
              page_slice_2col_img
              page_slice_2col_title
              page_slice_2col_richtext
              page_slice_2col_link_label
              page_slice_2col_link_destination {
                __typename
                ... on PRISMIC__ExternalLink {
                  url
                }
                ... on PRISMIC__FileLink {
                  name
                  url
                  size
                }
                ... on PRISMIC__Document {
                  __typename
                  _meta {
                    uid
                  }
                }
              }
            }
          }
          ... on PRISMIC_Template_pageBodyBlockquote {
            type
            label
            primary {
              blockquote
              credits
            }
          }
          ... on PRISMIC_Template_pageBodyList_interactive {
            type
            label
            primary {
              page_slice_list_richtext
              page_slice_list_title
            }
            fields {
              img
              richtext
              title
            }
          }
          ... on PRISMIC_Template_pageBodyVideo {
            type
            label
            primary {
              page_video_url
              page_video_caption
            }
          }
          ... on PRISMIC_Template_pageBodyCta_button {
            type
            label
            primary {
              label
              destination {
                __typename
                ... on PRISMIC__ExternalLink {
                  url
                }
                ... on PRISMIC__FileLink {
                  name
                  url
                  size
                }
                ... on PRISMIC__Document {
                  __typename
                  _meta {
                    uid
                  }
                }
              }
            }
          }
          ... on PRISMIC_Template_pageBodyEmbed_code {
            type
            label
            primary {
              embed_code
            }
          }
          ... on PRISMIC_Template_pageBodyImages_carousel {
            type
            label
            fields {
              image
              caption
            }
          }
        }
      }
      allShared_contentsuis {
        edges {
          node {
            global_page_billboard_title
            global_page_billboard_richtext
            global_page_billboard_link_label
            global_page_billboard_link_destination {
              __typename
              ... on PRISMIC__ExternalLink {
                url
              }
              ... on PRISMIC__FileLink {
                name
                url
                size
              }
              ... on PRISMIC__Document {
                __typename
                _meta {
                  uid
                }
              }
            }
          }
        }
      }
    }
  }
`;
 */
//Page.query = query;
//Page.fragments = [pageSlices];
