import React, { ReactNode } from 'react';

import s from './Container.scss';

interface IProps {
  children: ReactNode;
}

export const ContainerDiv = ({ children }: IProps) => (
  <div className={s.container}>{children}</div>
);
