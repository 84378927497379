import React from 'react';

import { Container } from 'components/container/Container';
import { RichText } from 'components/contentful/rich-text/RichText';
import { Button } from 'components/button/Button';

import { PrismicRichText } from 'types/prismicRichText';

import s from './Billboard.scss';

interface IProps {
  title?: string;
  text: PrismicRichText;
  extraText?: PrismicRichText;
  cta?: {
    label: string;
    href: string;
  };
  transparentBg?: boolean;
  vertical?:boolean;
  textCenter?:boolean;
  lessPadding?:boolean;
}

export const Billboard = ({ transparentBg=false, vertical=false, textCenter= false, lessPadding=false, billboard }: any) => (
  <div className={s('billboard', {transparentBg, vertical, textCenter, lessPadding})} >
    <Container>
      <div className={s.billboard__row}>
        <div className={s.billboard__row__col__left}>
          <h2 className={s.billboard__title}>{billboard?.title}</h2>
        </div>
        <div className={s.billboard__row__col__right}>
          {billboard.text && <RichText richText={billboard.text} />} 
          {billboard.extraText && <RichText richText={billboard.extraText}/>}          
          {billboard.cta && <div className={s.billboard__ctaContainer}>
            <Button label={billboard.cta.label} href={billboard.cta.href} ></Button></div>}
        </div>
      </div>
    </Container>
  </div>
);
