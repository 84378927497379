import React, { useRef, useEffect, useContext, useState } from 'react';
import  gsap from 'gsap';

import { Image } from 'components/image/Image';
import { scroll } from 'constants/Scroll'
import { UIContext } from 'context/ui';

import { isIpad } from 'utils/browserTargeting'

import s from './Mesh.scss';

export const Mesh = ({ atBottom=false, isInverted=false }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const { isMobile } = useContext(UIContext);

  useEffect(() => {
    if (canvasRef.current) {

      function normalize(value, min, max) {
        return (value - min) / (max - min);
      }

      function interpolate(value, min, max) {
        return min + (max - min) * value;
      }

      function map(value, min1, max1, min2, max2) {
        return interpolate(normalize(value, min1, max1), min2, max2);
      }

      let parameters = {
        size: 18,
        radius: 0.8,
        proximity: isMobile ? 200 : 325,
        growth: 4,
        ease: 0.075,
        color: '#77859E'
      };

      class Point {
        constructor(x, y) {
          this.x = x;
          this.y = y;
        }
      }

      class Circle {
        constructor(radius, x, y) {
          this._radius = radius;
          this.radius = radius;
          this.growthValue = 0;
          this.position = new Point(x, y);
        }

        /**
           * @param {CanvasRenderingContext2D} context
           * @param {number} ease
           */
        draw(context, ease) {
          this.radius += ((this._radius + this.growthValue) - (this.radius)) * ease;
          context.moveTo(this.position.x, this.position.y);
          context.arc(this.position.x, this.position.y, this.radius, 0, 2 * Math.PI);
        }

        addRadius(value) {
          this.growthValue = value;
        }

        get x() {
          return this.position.x;
        }

        set x(value) {
          this.position.x = value;
        }

        get y() {
          return this.position.y;
        }

        set y(value) {
          this.position.y = value;
        }
      }

      let raf = undefined;
      let circles = [];
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      const height = canvasRef.current.parentElement.offsetHeight;

      canvas.width = window.innerWidth;
      canvas.height = height;

      const setGrid = () => {
        circles = [];
        const { size, radius } = parameters;
        const columns = Math.ceil(window.innerWidth / size) + 1;
        const rows = Math.ceil(height / size) + 1;
        const amount = Math.ceil(columns * rows);

        for (let i = 0; i < amount; i++) {
          const column = i % columns;
          const row = ~~(i / columns);

          circles.push(new Circle(radius, size * column, size * row));
        }
      }

      const render = () => {
        if (canvasRef.current) {
          const { top, bottom, height } = canvasRef.current.getBoundingClientRect();

          if ((top - window.innerHeight <= 0 && bottom > 0) && context) {
            const { proximity, growth } = parameters;

            context.clearRect(0, 0, window.innerWidth, window.innerHeight);
            context.save();
            context.beginPath();
            context.globalCompositeOperation = 'overlay';
            context.fillStyle = parameters.color;
            context.clearRect(0, 0, window.innerWidth, window.innerHeight);

            const min = window.innerHeight;
            const max = window.innerHeight + height + window.innerHeight;
            const percentage = Math.abs(((top - min) * 100) / (max - min));

            let x;
            if (!atBottom) x = ((percentage - 0) / (100 - 0)) * (window.innerWidth - 0) + 0;
            else x = ((percentage - 0) / (100 - 0)) * (window.innerWidth * 1.5 - 0) + 0;

            let y;
            if (isMobile) y = ((percentage - 0) / (100 - 0)) * (height - 0) + 0;
            else y = ((percentage - 0) / (100 - 0)) * (height * 0.8 - 0) + 0;

            for (let circle of circles) {
              circle.draw(context, parameters.ease);

              let distance = Math.sqrt(Math.pow(circle.x - x, 2) + Math.pow(circle.y - y, 2));
              let d = map(distance, circle._radius, circle._radius + proximity, growth, 0);

              if (d < 0) d = 0;

              circle.addRadius(d);
            }

            context.fill();
            context.restore();
          }

          raf = requestAnimationFrame(render);
        }
      }

      const handleResize = () => {
        canvas.width = window.innerWidth;
        canvas.height = height;

        setGrid();
      }

      const addListeners = () => {
        window.addEventListener('resize', handleResize);
      }

      const removeListeners = () => {
        window.removeEventListener('resize', handleResize);
      }

      const init = () => {
        setGrid();
        render();
        addListeners();
      }

      const destroy = () => {
        removeListeners();
        cancelAnimationFrame(raf);
      }

      // setTimeout to make sure height is set properly
      setTimeout(() => {
        init();
      }, 1500)

      return () => destroy();
    }
  }, [])

  useEffect(() => {
    if (canvasRef.current) {
      gsap.fromTo(
        canvasRef.current,
        { autoAlpha: 0},
        {
          scrollTrigger: {
            scroller: scroll.container,
            trigger: canvasRef.current,
          },
          duration: 3,
          autoAlpha: 1
        }
      );
    }
  }, []);

  return (
    <div className={`${s.mesh} ${isInverted ? s.meshIsInverted : ''} ${isIpad() ? s.meshIsIpad : ''}`}>
      {!isIpad() && 
        <canvas ref={canvasRef} className={s.mesh__canvas}></canvas>
      }
      {isIpad() && 
        <img src={require('assets/images/mesh/mesh.jpg')} alt="Aurora" />
      }
    </div>
  );
};
